<template>
  <div>
    <classifiers-main></classifiers-main>
  </div>
</template>

<script>
import ClassifiersMain from "@/components/ecologist/classifiers/classifiersMain";
export default {
  name: "classifiersMainView",
  components: { ClassifiersMain },
};
</script>

<style scoped></style>
